import React from "react";
import Amplify, {API} from 'aws-amplify'
import awsconfig from '../../aws-exports'

Amplify.configure(awsconfig);

class FormContentJa extends React.Component {
    constructor() {
        super();
        this.state = {
            submitButton: true
        }
    }
    postInquiry = async body => {
        const APIName = 'satoAmplify'
        const path = '/inquiry'
        const params = {
            body: body,
        }
        return await API.post(APIName, path, params)
    }

    async submit() {
        if (this.state.submitButton === true) {
            const form = document.getElementById("submit_form")
            if (form.name.value === "" || form.company.value === "" || form.zip.value === "" || form.address.value === "" || form.tel.value === "" || form.email.value === "" || form.service.value === "" || form.inquiry.value === "") {
                window.alert('必須項目を入力してください')
                return
            }
            try {
                window.alert('お問い合わせの送信が完了しました。')
                this.setState({submitButton: false})
                await this.postInquiry({
                    name: form.name.value,
                    company: form.company.value,
                    department: form.department.value,
                    zip: form.zip.value,
                    address: form.address.value,
                    tel: form.tel.value,
                    fax: form.fax.value,
                    email: form.email.value,
                    service: form.service.value,
                    inquiry: form.inquiry.value,
                    locale: 'ja'
                })
            } catch (e) {
                window.alert('お問い合わせの送信に失敗しました。')
            }
        } else {
            window.alert('お問い合わせの送信は完了しています。\n別のお問い合わせメールを送信する場合は、ページの更新またはリセットボタンを押してください。')
        }
    }

    resetBtnOperation() {
        this.name.value = "";
        this.company.value = "";
        this.department.value = "";
        this.zip.value = "";
        this.address.value = "";
        this.tel.value = "";
        this.fax.value = "";
        this.email.value = "";
        this.inquiry.value = "";
        this.setState({submitButton: true})
    }

    render() {
        return (
            <div className="mail-form">
                <h3>お問い合わせフォーム</h3>
                <p className="form-description">取扱説明書をご要望の場合、対象製品の製品ページに掲載しています。</p>
                <p className="asterisk-text">の部分は必須項目です。</p>
                <form id="submit_form">
                    <table>
                        <tbody>
                        <tr>
                            <td className="name">ご氏名</td>
                            <td><input type="text" id="name" name="name" placeholder="ご氏名" ref={(el) => (this.name = el)} /></td>
                        </tr>
                        <tr>
                            <td className="company">御社名</td>
                            <td className="company-td">
                                <input type="text" id="company" name="company" placeholder="御社名" ref={(el) => (this.company = el)} />
                                <br/><span className="company-text">個人のお客様の場合は「個人」とご記入ください。</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="department">所属部署名<span className="not-required">（任意）</span></td>
                            <td><input type="text" id="department" name="department" placeholder="所属部署名" ref={(el) => (this.department = el)} /></td>
                        </tr>
                        <tr>
                            <td className="zip">郵便番号</td>
                            <td><input type="text" id="zip" name="zip" placeholder="郵便番号" ref={(el) => (this.zip = el)} /></td>
                        </tr>
                        <tr>
                            <td className="address">ご住所</td>
                            <td><input type="text" id="address" name="address" placeholder="ご住所" ref={(el) => (this.address = el)} /></td>
                        </tr>
                        <tr>
                            <td className="telephone">電話番号</td>
                            <td><input type="text" id="tel" name="tel" placeholder="電話番号" ref={(el) => (this.tel = el)} /></td>
                        </tr>
                        <tr>
                            <td className="fax">FAX番号<span className="not-required">（任意）</span></td>
                            <td><input type="text" id="fax" name="fax" placeholder="FAX番号" ref={(el) => (this.fax = el)} /></td>
                        </tr>
                        <tr>
                            <td className="e-mail">E-Mail</td>
                            <td><input type="text" id="e-mail" name="email" placeholder="E-Mail" ref={(el) => (this.email = el)} /></td>
                        </tr>
                        <tr>
                            <td className="classification">お問い合わせ分類</td>
                            <td>
                                <div className="radio">
                                    <label><input type="radio" className="service" name="service" value="お問い合わせ（購入前相談）" /><span>お問い合わせ（購入前相談）</span></label><br />
                                    <label><input type="radio" className="service" name="service" value="故障・修理・検査のご相談" /><span>故障・修理・検査のご相談</span></label><br />
                                    <label><input type="radio" className="service" name="service" value="操作方法・はかり方・設定方法など" /><span>操作方法・はかり方・設定方法など</span></label><br />
                                    <label><input type="radio" className="service" name="service" value="部品・カタログ・取扱説明書等お取り寄せ" /><span>部品・カタログ・取扱説明書等お取り寄せ</span></label><br />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="inquiry-content">お問い合わせ内容</td>
                            <td><textarea id="inquiry" name="inquiry" rows="8" placeholder="お問い合わせ内容" ref={(el) => (this.inquiry = el)} >
                                                </textarea></td>
                        </tr>
                        </tbody>
                    </table>
                </form>
                <div className="confirmation-pc">
                    <input className="reset-btn" type="button" value="リセット" onClick={()=>this.resetBtnOperation()} name="reset">
                    </input>
                    <input className="confirm-btn" type="button" value="送信" name="confirm" onClick={()=>this.submit()}>
                    </input>
                </div>
                <div className="confirmation-sp">
                    <input className="confirm-btn" type="button" value="送信" name="confirm" onClick={()=>this.submit()}>
                    </input>
                    <input className="reset-btn" type="button" value="リセット" onClick={()=>this.resetBtnOperation()} name="reset">
                    </input>
                </div>
            </div>
        )
    }
}

class FormContentEn extends React.Component {
    constructor() {
        super();
        this.state = {
            submitButton: true
        }
    }
    postInquiry = async body => {
        const APIName = 'satoAmplify'
        const path = '/inquiry'
        const params = {
            body: body,
        }
        return await API.post(APIName, path, params)
    }

    async submit() {
        if (this.state.submitButton === true) {
            const form = document.getElementById("submit_form")
            if (form.name.value === "" || form.address.value === "" || form.country.value === "" || form.business.value === "" || form.tel.value === "" || form.email.value === "" || form.inquiry.value === "") {
                window.alert('Please fill in the required fields.')
                return
            }
            try {
                window.alert('Your inquiry has been sent.')
                this.setState({submitButton: false})
                await this.postInquiry({
                    name: form.name.value,
                    department: form.department.value,
                    company: form.company.value,
                    address: form.address.value,
                    country: form.country.value,
                    business: form.business.value,
                    others: form.others.value,
                    tel: form.tel.value,
                    fax: form.fax.value,
                    email: form.email.value,
                    web: form.web.value,
                    inquiry: form.inquiry.value,
                    locale: 'en'
                })
            } catch (e) {
                window.alert(e)
            }
        } else {
            window.alert('Your inquiry has already been sent.\nIf you wish to send another inquiry email, please refresh the page or press the reset button.')
        }
    }

    resetBtnOperation() {
        this.name.value = "";
        this.department.value = "";
        this.company.value = "";
        this.address.value = "";
        this.country.value = "";
        this.others.value = "";
        this.tel.value = "";
        this.fax.value = "";
        this.email.value = "";
        this.inquiry.value = "";
    }

    render() {
        console.log(this)
        return (
            <div className="mail-form">
                <h3>Contact Form</h3>
                <form id="submit_form">
                    <table>
                        <tbody>
                        <tr>
                            <td className="name">Name</td>
                            <td><input type="text" id="name" name="name" placeholder="Name" ref={(el) => (this.name = el)} /></td>
                        </tr>
                        <tr>
                            <td className="department">Department or Job title<span className="not-required">(optional）</span></td>
                            <td><input type="text" id="department" name="department" placeholder="Department or Job title" ref={(el) => (this.department = el)} />
                            </td>
                        </tr>
                        <tr>
                            <td className="company">Company Name</td>
                            <td><input type="text" id="company" name="company" placeholder="Company Name" ref={(el) => (this.company = el)} /></td>
                        </tr>
                        <tr>
                            <td className="address">Address</td>
                            <td><input type="text" id="address" name="address" placeholder="Address" ref={(el) => (this.address = el)} /></td>
                        </tr>
                        <tr>
                            <td className="address">Country</td>
                            <td><input type="text" id="country" name="country" placeholder="Country" ref={(el) => (this.country = el)} /></td>
                        </tr>
                        <tr>
                            <td className="classification">Type of Business</td>
                            <td>
                                <div className="radio">
                                    <label><input type="radio" className="business" name="business" value="Wholesaler" /><span>Wholesaler</span></label><br />
                                    <label><input type="radio" className="business" name="business" value="Retailer" /><span>Retailer</span></label><br />
                                    <label><input type="radio" className="business" name="business" value="End-user" /><span>End-user</span></label><br />
                                    <label><input type="radio" className="business" name="business" value="Others" /><span>Others</span></label>
                                    <input type="text" id="others" name="others" placeholder="Others" ref={(el) => (this.others = el)}/><br />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="telephone">Phone</td>
                            <td><input type="text" id="tel" name="tel" placeholder="Phone" ref={(el) => (this.tel = el)} /></td>
                        </tr>
                        <tr>
                            <td className="fax">Fax<span className="not-required">（optional）</span></td>
                            <td><input type="text" id="fax" name="fax" placeholder="Fax" ref={(el) => (this.fax = el)} /></td>
                        </tr>
                        <tr>
                            <td className="e-mail">Email</td>
                            <td><input type="text" id="e-mail" name="email" placeholder="Email" ref={(el) => (this.email = el)} /></td>
                        </tr>
                        <tr>
                            <td className="web">Website</td>
                            <td><input type="text" id="web" name="web" placeholder="Website" ref={(el) => (this.web = el)} /></td>
                        </tr>
                        <tr>
                            <td className="inquiry-content">Comments</td>
                            <td><textarea id="inquiry" name="inquiry" rows="8" placeholder="Comments" ref={(el) => (this.inquiry = el)} >
                                                </textarea></td>
                        </tr>
                        </tbody>
                    </table>
                </form>
                <div className="confirmation-pc">
                    <input className="reset-btn" type="button" value="reset" onClick={()=>this.resetBtnOperation()} name="reset">
                    </input>
                    <input className="confirm-btn" type="button" value="submit" name="confirm" onClick={()=>this.submit()}>
                    </input>
                </div>
                <div className="confirmation-sp">
                    <input className="confirm-btn" type="button" value="submit" name="confirm" onClick={()=>this.submit()}>
                    </input>
                    <input className="reset-btn" type="button" value="reset" onClick={()=>this.resetBtnOperation()} name="reset">
                    </input>
                </div>
            </div>
        )
    }
}

const ContactForm = () => {
    const pathName = typeof window !== 'undefined' ? window.location.pathname : '';
    return (
        <div>
            {
                (pathName.indexOf('/en') !== -1) ?
                    <FormContentEn />:
                    <FormContentJa />
            }
        </div>
    )
}

export default ContactForm;